import { createSlice } from '@reduxjs/toolkit'

import {
  FETCH_STATUS_IDLE, FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_ERROR,
} from '../../../utils/request/status'

const initialState = {
  report_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  report_list_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  add_report_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  update_report_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  remove_report_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  fetch_report_thumbnail_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  reportThumbnail: undefined,
  reportList: [],
  reportData: undefined,
}

const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    getReportDataRequest: (state) => {
      state.report_status.status = FETCH_STATUS_REQUEST
    },
    getReportDataSuccess: (state, action) => {
      state.report_status.status = FETCH_STATUS_SUCCESS
      state.report_status.error = undefined
      state.reportData = action.payload.report
    },
    getReportDataFailure: (state, action) => {
      state.report_status.status = FETCH_STATUS_ERROR
      state.report_status.error = action.payload
    },
    getReportDataIdle: (state) => {
      state.report_status.status = FETCH_STATUS_IDLE
      state.report_status.error = undefined
    },
    getListReportRequest: (state) => {
      state.report_list_status.status = FETCH_STATUS_REQUEST
    },
    getListReportSuccess: (state, action) => {
      state.report_list_status.status = FETCH_STATUS_SUCCESS
      state.report_list_status.error = undefined
      state.reportList = action.payload
    },
    getListReportError: (state, action) => {
      state.report_list_status.status = FETCH_STATUS_ERROR
      state.report_list_status.error = action.payload
    },
    getListReportIdle: (state) => {
      state.report_list_status.status = FETCH_STATUS_IDLE
      state.report_list_status.error = undefined
    },
    addReportRequest: (state) => {
      state.add_report_status.status = FETCH_STATUS_REQUEST
    },
    addReportSuccess: (state) => {
      state.add_report_status.status = FETCH_STATUS_SUCCESS
      state.add_report_status.error = undefined
    },
    addReportFailure: (state, action) => {
      state.add_report_status.status = FETCH_STATUS_ERROR
      state.add_report_status.error = action.payload
    },
    addReportIdle: (state) => {
      state.add_report_status.status = FETCH_STATUS_IDLE
    },
    updateReportRequest: (state) => {
      state.update_report_status.status = FETCH_STATUS_REQUEST
    },
    updateReportSuccess: (state) => {
      state.update_report_status.status = FETCH_STATUS_SUCCESS
      state.update_report_status.error = undefined
    },
    updateReportFailure: (state, action) => {
      state.update_report_status.status = FETCH_STATUS_ERROR
      state.update_report_status.error = action.payload
    },
    updateReportIdle: (state) => {
      state.update_report_status.status = FETCH_STATUS_IDLE
    },
    removeReportRequest: (state) => {
      state.remove_report_status.status = FETCH_STATUS_REQUEST
    },
    removeReportSuccess: (state) => {
      state.remove_report_status.status = FETCH_STATUS_SUCCESS
      state.remove_report_status.error = undefined
    },
    removeReportFailure: (state, action) => {
      state.remove_report_status.status = FETCH_STATUS_ERROR
      state.remove_report_status.error = action.payload
    },
    removeReportIdle: (state) => {
      state.remove_report_status.status = FETCH_STATUS_IDLE
      state.remove_report_status.error = undefined
    },
    fetchReportThumbnailRequest: (state) => {
      state.fetch_report_thumbnail_status.status = FETCH_STATUS_REQUEST
    },
    fetchReportThumbnailSuccess: (state, action) => {
      state.fetch_report_thumbnail_status.status = FETCH_STATUS_SUCCESS
      state.fetch_report_thumbnail_status.error = undefined
      state.reportThumbnail = action.payload
    },
    fetchReportThumbnailFailure: (state, action) => {
      state.fetch_report_thumbnail_status.status = FETCH_STATUS_ERROR
      state.fetch_report_thumbnail_status.error = action.payload
    },
    fetchReportThumbnailIdle: (state) => {
      state.fetch_report_thumbnail_status.status = FETCH_STATUS_IDLE
      state.fetch_report_thumbnail_status.error = undefined
    },
  },
})

export const reportState = (state) => state.report

const { actions, reducer } = reportSlice

export const {
  getReportDataRequest,
  getReportDataSuccess,
  getReportDataFailure,
  getReportDataIdle,
  getListReportRequest,
  getListReportSuccess,
  getListReportError,
  getListReportIdle,
  addReportRequest,
  addReportSuccess,
  addReportFailure,
  addReportIdle,
  updateReportRequest,
  updateReportSuccess,
  updateReportFailure,
  updateReportIdle,
  removeReportRequest,
  removeReportSuccess,
  removeReportFailure,
  removeReportIdle,
  fetchReportThumbnailRequest,
  fetchReportThumbnailSuccess,
  fetchReportThumbnailFailure,
  fetchReportThumbnailIdle,
} = actions

export default reducer
