import { createSlice } from '@reduxjs/toolkit'

import {
  FETCH_STATUS_IDLE, FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_ERROR,
} from '../../../utils/request/status'

const initialState = {
  load_staff_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  list_staff_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  add_staff_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  update_staff_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  remove_staff_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  resend_email_staff_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  listStaff: [],
  staff: undefined,
}

const staffSlice = createSlice({
  name: 'staff',
  initialState,
  reducers: {
    getListStaffRequest: (state) => {
      state.list_staff_status.status = FETCH_STATUS_REQUEST
    },
    getListStaffSuccess: (state, action) => {
      state.list_staff_status.status = FETCH_STATUS_SUCCESS
      state.list_staff_status.error = undefined
      state.listStaff = action.payload
    },
    getListStaffFailure: (state, action) => {
      state.list_staff_status.status = FETCH_STATUS_ERROR
      state.list_staff_status.error = action.payload
    },
    getListStaffIdle: (state) => {
      state.list_staff_status.status = FETCH_STATUS_IDLE
      state.list_staff_status.error = undefined
    },
    getStaffRequest: (state) => {
      state.load_staff_status.status = FETCH_STATUS_REQUEST
    },
    getStaffSuccess: (state, action) => {
      state.load_staff_status.status = FETCH_STATUS_SUCCESS
      state.load_staff_status.error = undefined
      state.staff = action.payload
    },
    getStaffFailure: (state, action) => {
      state.load_staff_status.status = FETCH_STATUS_ERROR
      state.load_staff_status.error = action.payload
    },
    getStaffIdle: (state) => {
      state.load_staff_status.status = FETCH_STATUS_IDLE
      state.load_staff_status.error = undefined
    },
    addStaffRequest: (state) => {
      state.add_staff_status.status = FETCH_STATUS_REQUEST
    },
    addStaffSuccess: (state) => {
      state.add_staff_status.status = FETCH_STATUS_SUCCESS
      state.add_staff_status.error = undefined
    },
    addStaffFailure: (state, action) => {
      state.add_staff_status.status = FETCH_STATUS_ERROR
      state.add_staff_status.error = action.payload
    },
    addStaffIdle: (state) => {
      state.add_staff_status.status = FETCH_STATUS_IDLE
      state.add_staff_status.error = undefined
    },
    updateStaffRequest: (state) => {
      state.update_staff_status.status = FETCH_STATUS_REQUEST
    },
    updateStaffSuccess: (state) => {
      state.update_staff_status.status = FETCH_STATUS_SUCCESS
      state.update_staff_status.error = undefined
    },
    updateStaffFailure: (state, action) => {
      state.update_staff_status.status = FETCH_STATUS_ERROR
      state.update_staff_status.error = action.payload
    },
    updateStaffIdle: (state) => {
      state.update_staff_status.status = FETCH_STATUS_IDLE
      state.update_staff_status.error = undefined
    },
    removeStaffRequest: (state) => {
      state.remove_staff_status.status = FETCH_STATUS_REQUEST
    },
    removeStaffSuccess: (state) => {
      state.remove_staff_status.status = FETCH_STATUS_SUCCESS
      state.remove_staff_status.error = undefined
    },
    removeStaffFailure: (state, action) => {
      state.remove_staff_status.status = FETCH_STATUS_ERROR
      state.remove_staff_status.error = action.payload
    },
    removeStaffIdle: (state) => {
      state.remove_staff_status.status = FETCH_STATUS_IDLE
      state.remove_staff_status.error = undefined
    },
    resendRegisterEmailRequest: (state) => {
      state.resend_email_staff_status.status = FETCH_STATUS_REQUEST
    },
    resendRegisterEmailSuccess: (state) => {
      state.resend_email_staff_status.status = FETCH_STATUS_SUCCESS
      state.resend_email_staff_status.error = undefined
    },
    resendRegisterEmailFailure: (state, action) => {
      state.resend_email_staff_status.status = FETCH_STATUS_ERROR
      state.resend_email_staff_status.error = action.payload
    },
    resendRegisterEmailIdle: (state) => {
      state.resend_email_staff_status.status = FETCH_STATUS_IDLE
      state.resend_email_staff_status.error = undefined
    },
  },
})

const { actions, reducer } = staffSlice

export const {
  getListStaffRequest,
  getListStaffSuccess,
  getListStaffFailure,
  getListStaffIdle,
  getStaffRequest,
  getStaffSuccess,
  getStaffFailure,
  getStaffIdle,
  addStaffRequest,
  addStaffSuccess,
  addStaffFailure,
  addStaffIdle,
  updateStaffRequest,
  updateStaffSuccess,
  updateStaffFailure,
  updateStaffIdle,
  removeStaffRequest,
  removeStaffSuccess,
  removeStaffFailure,
  removeStaffIdle,
  resendRegisterEmailRequest,
  resendRegisterEmailSuccess,
  resendRegisterEmailFailure,
  resendRegisterEmailIdle,
} = actions

export const staffState = (state) => state.staff

export default reducer
