import { createSlice } from '@reduxjs/toolkit'
import { storage } from '../../../utils/token'

import {
  FETCH_STATUS_IDLE, FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_ERROR,
} from '../../../utils/request/status'

const initialState = {
  resend_otp_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  login_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  load_user_data_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  forget_password_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  vertify_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  verificationNeeded: false,
  isLogin: !!storage.get('jwtToken'),
  user: undefined,
  phone: undefined,
  preToken: undefined,
  isTokenExpire: false,
  change_password_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  check_link_token_expired: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  isLinkTokenExpire: undefined,
  set_password_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginRequest: (state) => {
      state.login_status.status = FETCH_STATUS_REQUEST
      state.login_status.error = undefined
    },
    loginSuccess: (state, action) => {
      state.verificationNeeded = action.payload.expired
      state.phone = action.payload.phone
      state.isTokenExpire = false
      state.preToken = action.payload.expired ? action.payload.token : undefined
      state.isLogin = !action.payload.expired
    },
    loginFailure: (state, action) => {
      state.login_status.status = FETCH_STATUS_ERROR
      state.login_status.error = action.payload
    },
    loginIdle: (state) => {
      state.login_status.status = FETCH_STATUS_IDLE
    },
    logoutSuccess: (state) => {
      state.isLogin = false
      state.isTokenExpire = false
    },
    verifyOTPRequest: (state) => {
      state.vertify_status.status = FETCH_STATUS_REQUEST
    },
    verifyOTPSuccess: (state) => {
      state.vertify_status.status = FETCH_STATUS_SUCCESS
      state.vertify_status.error = undefined
      state.isLogin = true
      state.verificationNeeded = false
      state.isTokenExpire = false
    },
    verifyOTPFailure: (state, action) => {
      state.vertify_status.status = FETCH_STATUS_ERROR
      state.vertify_status.error = action.payload
      state.islogin = false
      state.verificationNeeded = true
    },
    verifyOTPIdle: (state) => {
      state.vertify_status.status = FETCH_STATUS_IDLE
    },
    resendOTPRequest: (state) => {
      state.resend_otp_status.status = FETCH_STATUS_REQUEST
    },
    resendOTPSuccess: (state) => {
      state.resend_otp_status.status = FETCH_STATUS_SUCCESS
      state.resend_otp_status.error = undefined
    },
    resendOTPFailure: (state, action) => {
      state.resend_otp_status.status = FETCH_STATUS_ERROR
      state.resend_otp_status.error = action.payload
    },
    resendOTPIdle: (state) => {
      state.resend_otp_status.status = FETCH_STATUS_IDLE
      state.resend_otp_status.error = undefined
    },
    loadUserDataRequest: (state) => {
      state.load_user_data_status.status = FETCH_STATUS_REQUEST
    },
    loadUserDataSuccess: (state, action) => {
      state.load_user_data_status.status = FETCH_STATUS_SUCCESS
      state.load_user_data_status.error = undefined
      state.user = action.payload
    },
    loadUserDataFailure: (state, action) => {
      state.load_user_data_status.status = FETCH_STATUS_ERROR
      state.load_user_data_status.error = action.payload
    },
    loadUserDataIdle: (state) => {
      state.load_user_data_status.status = FETCH_STATUS_IDLE
    },
    setTokenExpire: (state, action) => {
      state.isTokenExpire = action.payload
    },
    forgotPasswordRequest: (state) => {
      state.forget_password_status.status = FETCH_STATUS_REQUEST
    },
    forgotPasswordSuccess: (state) => {
      state.forget_password_status.status = FETCH_STATUS_SUCCESS
      state.forget_password_status.error = undefined
    },
    forgotPasswordFailure: (state, action) => {
      state.forget_password_status.status = FETCH_STATUS_ERROR
      state.forget_password_status.error = action.payload
    },
    forgotPasswordIdle: (state) => {
      state.forget_password_status.status = FETCH_STATUS_IDLE
    },
    changePasswordRequest: (state) => {
      state.change_password_status.status = FETCH_STATUS_REQUEST
      state.change_password_status.error = undefined
    },
    changePasswordSuccess: (state) => {
      state.change_password_status.status = FETCH_STATUS_SUCCESS
    },
    changePasswordFailure: (state, action) => {
      state.change_password_status.status = FETCH_STATUS_ERROR
      state.change_password_status.error = action.payload
    },
    changePasswordIdle: (state) => {
      state.change_password_status.status = FETCH_STATUS_IDLE
    },
    checkTokenExpireRequest: (state) => {
      state.check_link_token_expired.status = FETCH_STATUS_REQUEST
    },
    checkTokenExpireSuccess: (state) => {
      state.check_link_token_expired.status = FETCH_STATUS_SUCCESS
      state.isLinkTokenExpire = false
    },
    checkTokenExpireFailure: (state, action) => {
      state.check_link_token_expired.status = FETCH_STATUS_ERROR
      state.check_link_token_expired.error = action.payload
      state.isLinkTokenExpire = true
    },
    checkTokenExpireIdle: (state) => {
      state.check_link_token_expired.status = FETCH_STATUS_IDLE
    },
    setPasswordRequest: (state) => {
      state.set_password_status.status = FETCH_STATUS_REQUEST
    },
    setPasswordSuccess: (state) => {
      state.set_password_status.status = FETCH_STATUS_SUCCESS
      state.isLinkTokenExpire = false
    },
    setPasswordFailure: (state, action) => {
      state.set_password_status.status = FETCH_STATUS_ERROR
      state.set_password_status.error = action.payload
      state.isLinkTokenExpire = true
    },
    setPasswordIdle: (state) => {
      state.set_password_status.status = FETCH_STATUS_IDLE
    },
  },
})

export const userState = (state) => state?.user
export const userRoleState = (state) => state?.user.user?.roles[0]

const { actions, reducer } = userSlice

export const {
  loginRequest,
  loginSuccess,
  loginFailure,
  loginIdle,
  logoutSuccess,
  verifyOTPRequest,
  verifyOTPSuccess,
  verifyOTPFailure,
  verifyOTPIdle,
  resendOTPRequest,
  resendOTPSuccess,
  resendOTPFailure,
  resendOTPIdle,
  loadUserDataRequest,
  loadUserDataSuccess,
  loadUserDataFailure,
  loadUserDataIdle,
  setTokenExpire,
  forgotPasswordRequest,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  forgotPasswordIdle,
  changePasswordRequest,
  changePasswordSuccess,
  changePasswordFailure,
  changePasswordIdle,
  checkTokenExpireRequest,
  checkTokenExpireSuccess,
  checkTokenExpireFailure,
  checkTokenExpireIdle,
  setPasswordRequest,
  setPasswordSuccess,
  setPasswordFailure,
  setPasswordIdle,
} = actions

export default reducer
