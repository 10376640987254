import { createSlice } from '@reduxjs/toolkit'
import {
  FETCH_STATUS_IDLE,
  FETCH_STATUS_REQUEST,
  FETCH_STATUS_SUCCESS,
  FETCH_STATUS_ERROR,
} from '../../../utils/request/status'

const initialState = {
  monthly_report_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  monthlyReportData: [],
}

const monthlyReportSlice = createSlice({
  name: 'monthlyReport',
  initialState,
  reducers: {
    getMonthlyReportRequest: (state) => {
      state.monthly_report_status.status = FETCH_STATUS_REQUEST
    },
    getMonthlyReportSuccess: (state, action) => {
      state.monthly_report_status.status = FETCH_STATUS_SUCCESS
      state.monthlyReportData = action.payload
    },
    getMonthlyReportFailure: (state, action) => {
      state.monthly_report_status.status = FETCH_STATUS_ERROR
      state.monthly_report_status.error = action.payload
    },
    getMonthlyReportIdle: (state) => {
      state.monthly_report_status.status = FETCH_STATUS_IDLE
    },
  },
})

export const monthlyReportState = (state) => state.monthly_report

const { actions, reducer } = monthlyReportSlice

export const {
  getMonthlyReportRequest,
  getMonthlyReportSuccess,
  getMonthlyReportFailure,
  getMonthlyReportIdle,
} = actions

export default reducer
