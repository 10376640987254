import { createSlice } from '@reduxjs/toolkit'

import {
  FETCH_STATUS_IDLE, FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_ERROR,
} from '../../../utils/request/status'

const initialState = {
  team_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  team_by_id_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  add_team_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  update_team_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  remove_team_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  teamList: [],
  teamById: [],
}

const teamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    getTeamListRequest: (state) => {
      state.team_status.status = FETCH_STATUS_REQUEST
    },
    getTeamListSuccess: (state, action) => {
      state.team_status.status = FETCH_STATUS_SUCCESS
      state.team_status.error = undefined
      state.teamList = action.payload
    },
    getTeamListFailure: (state, action) => {
      state.team_status.status = FETCH_STATUS_ERROR
      state.team_status.error = action.payload
    },
    getTeamListIdle: (state) => {
      state.team_status.status = FETCH_STATUS_IDLE
      state.team_status.error = undefined
    },
    getTeamByIdRequest: (state) => {
      state.team_by_id_status.status = FETCH_STATUS_REQUEST
    },
    getTeamByIdSuccess: (state, action) => {
      state.team_by_id_status.status = FETCH_STATUS_SUCCESS
      state.team_by_id_status.error = undefined
      state.teamById = action.payload
    },
    getTeamByIdFailure: (state, action) => {
      state.team_by_id_status.status = FETCH_STATUS_ERROR
      state.team_by_id_status.error = action.payload
    },
    getTeamByIdtIdle: (state) => {
      state.team_by_id_status.status = FETCH_STATUS_IDLE
    },
    addTeamRequest: (state) => {
      state.add_team_status.status = FETCH_STATUS_REQUEST
    },
    addTeamSuccess: (state) => {
      state.add_team_status.status = FETCH_STATUS_SUCCESS
      state.add_team_status.error = undefined
    },
    addTeamFailure: (state, action) => {
      state.add_team_status.status = FETCH_STATUS_ERROR
      state.add_team_status.error = action.payload
    },
    addTeamIdle: (state) => {
      state.add_team_status.status = FETCH_STATUS_IDLE
    },
    updateTeamRequest: (state) => {
      state.update_team_status.status = FETCH_STATUS_REQUEST
    },
    updateTeamSuccess: (state) => {
      state.update_team_status.status = FETCH_STATUS_SUCCESS
      state.update_team_status.error = undefined
    },
    updateTeamFailure: (state, action) => {
      state.update_team_status.status = FETCH_STATUS_ERROR
      state.update_team_status.error = action.payload
    },
    updateTeamIdle: (state) => {
      state.update_team_status.status = FETCH_STATUS_IDLE
    },
    removeTeamRequest: (state) => {
      state.remove_team_status.status = FETCH_STATUS_REQUEST
    },
    removeTeamSuccess: (state) => {
      state.remove_team_status.status = FETCH_STATUS_SUCCESS
      state.remove_team_status.error = undefined
    },
    removeTeamFailure: (state, action) => {
      state.remove_team_status.status = FETCH_STATUS_ERROR
      state.remove_team_status.error = action.payload
    },
    removeTeamIdle: (state) => {
      state.remove_team_status.status = FETCH_STATUS_REQUEST
      state.remove_team_status.error = undefined
    },
  },
})

export const teamState = (state) => state.team
export const teamListState = (state) => state.team.teamList
export const teamByIdState = (state) => state.team.teamById

const { actions, reducer } = teamSlice

export const {
  getTeamListRequest,
  getTeamListSuccess,
  getTeamListFailure,
  getTeamListIdle,
  getTeamByIdRequest,
  getTeamByIdSuccess,
  getTeamByIdFailure,
  getTeamByIdtIdle,
  addTeamRequest,
  addTeamSuccess,
  addTeamFailure,
  addTeamIdle,
  updateTeamRequest,
  updateTeamSuccess,
  updateTeamFailure,
  updateTeamIdle,
  removeTeamRequest,
  removeTeamSuccess,
  removeTeamFailure,
  removeTeamIdle,
} = actions

export default reducer
