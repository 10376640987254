import { createSlice } from '@reduxjs/toolkit'

import {
  FETCH_STATUS_IDLE,
  FETCH_STATUS_REQUEST,
  FETCH_STATUS_SUCCESS,
  FETCH_STATUS_ERROR,
} from '../../../utils/request/status'

const initialState = {
  team_report_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  folder_team_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
}

const crossFunctionSlice = createSlice({
  name: 'crossFunction',
  initialState,
  reducers: {
    updateTeamReportRequest: (state) => {
      state.team_report_status.status = FETCH_STATUS_REQUEST
    },
    updateTeamReportSuccess: (state) => {
      state.team_report_status.status = FETCH_STATUS_SUCCESS
      state.team_report_status.error = undefined
    },
    updateTeamReportFailure: (state, action) => {
      state.team_report_status.status = FETCH_STATUS_ERROR
      state.team_report_status.error = action.payload
    },
    updateTeamReportIdle: (state) => {
      state.team_report_status.status = FETCH_STATUS_IDLE
    },
    updateFolderTeamRequest: (state) => {
      state.folder_team_status.status = FETCH_STATUS_REQUEST
    },
    updateFolderTeamSuccess: (state) => {
      state.folder_team_status.status = FETCH_STATUS_SUCCESS
      state.team_report_status.error = undefined
    },
    updateFolderTeamFailure: (state, action) => {
      state.folder_team_status.status = FETCH_STATUS_ERROR
      state.folder_team_status.error = action.payload
    },
    updateFolderTeamIdle: (state) => {
      state.folder_team_status.status = FETCH_STATUS_IDLE
    },
  },
})

export const crossFunctionState = (state) => state.crossFunction

const { actions, reducer } = crossFunctionSlice

export const {
  updateTeamReportRequest,
  updateTeamReportSuccess,
  updateTeamReportFailure,
  updateTeamReportIdle,
  updateFolderTeamRequest,
  updateFolderTeamSuccess,
  updateFolderTeamFailure,
  updateFolderTeamIdle,
} = actions

export default reducer
