import { createSlice } from '@reduxjs/toolkit'
import {
  FETCH_STATUS_IDLE, FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_ERROR,
} from '../../../utils/request/status'

const initialState = {
  authorized: false,
  defaultExpiredDateRange: undefined,
  defaultRetentionDateRange: undefined,
  supplierList: [],
  supplier: undefined,
  showSupplierResModal: false,
  get_expired_date_range_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  get_retention_date_range_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  update_retention_date_range_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  authorized_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  supplier_list_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  add_supplier_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  update_supplier_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  remove_supplier_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  supplier_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
}

const supplierSlice = createSlice({
  name: 'supplier',
  initialState,
  reducers: {
    getDefaultExpiredDateRangeRequest: (state) => {
      state.get_expired_date_range_status.status = FETCH_STATUS_REQUEST
    },
    getDefaultExpiredDateRangeSuccess: (state, action) => {
      state.get_expired_date_range_status.status = FETCH_STATUS_SUCCESS
      state.get_expired_date_range_status.error = undefined
      state.defaultExpiredDateRange = action.payload.defaultExpiredDateRange
    },
    getDefaultExpiredDateRangeFailure: (state, action) => {
      state.get_expired_date_range_status.status = FETCH_STATUS_ERROR
      state.get_expired_date_range_status.error = action.payload
    },
    getDefaultExpiredDateRangeIdle: (state) => {
      state.get_expired_date_range_status.status = FETCH_STATUS_IDLE
      state.get_expired_date_range_status.error = undefined
    },
    getDefaultRetentionDateRangeRequest: (state) => {
      state.get_retention_date_range_status.status = FETCH_STATUS_REQUEST
    },
    getDefaultRetentionDateRangeSuccess: (state, action) => {
      state.get_retention_date_range_status.status = FETCH_STATUS_SUCCESS
      state.get_retention_date_range_status.error = undefined
      state.defaultRetentionDateRange = action.payload.range
    },
    getDefaultRetentionDateRangeFailure: (state, action) => {
      state.get_retention_date_range_status.status = FETCH_STATUS_ERROR
      state.get_retention_date_range_status.error = action.payload
    },
    getDefaultRetentionDateRangeIdle: (state) => {
      state.get_retention_date_range_status.status = FETCH_STATUS_IDLE
      state.get_retention_date_range_status.error = undefined
    },
    updateDefaultRetentionDateRangeRequest: (state) => {
      state.update_retention_date_range_status.status = FETCH_STATUS_REQUEST
    },
    updateDefaultRetentionDateRangeSuccess: (state) => {
      state.update_retention_date_range_status.status = FETCH_STATUS_SUCCESS
      state.update_retention_date_range_status.error = undefined
    },
    updateDefaultRetentionDateRangeFailure: (state, action) => {
      state.update_retention_date_range_status.status = FETCH_STATUS_ERROR
      state.update_retention_date_range_status.error = action.payload
    },
    updateDefaultRetentionDateRangeIdle: (state) => {
      state.update_retention_date_range_status.status = FETCH_STATUS_IDLE
      state.update_retention_date_range_status.error = undefined
    },
    getListSupplierRequest: (state) => {
      state.supplier_list_status.status = FETCH_STATUS_REQUEST
    },
    getListSupplierSuccess: (state, action) => {
      state.supplier_list_status.status = FETCH_STATUS_SUCCESS
      state.supplier_list_status.error = undefined
      state.supplierList = action.payload
    },
    getListSupplierFailure: (state, action) => {
      state.supplier_list_status.status = FETCH_STATUS_ERROR
      state.supplier_list_status.error = action.payload
    },
    getListSupplierIdle: (state) => {
      state.supplier_list_status.status = FETCH_STATUS_IDLE
      state.supplier_list_status.error = undefined
    },
    addSupplierRequest: (state) => {
      state.add_supplier_status.status = FETCH_STATUS_REQUEST
    },
    addSupplierSuccess: (state, action) => {
      state.add_supplier_status.status = FETCH_STATUS_SUCCESS
      state.add_supplier_status.response = action.payload
      state.showSupplierResModal = true
    },
    addSupplierFailure: (state, action) => {
      state.add_supplier_status.status = FETCH_STATUS_ERROR
      state.add_supplier_status.error = action.payload.response.data.error
    },
    addSupplierIdle: (state) => {
      state.add_supplier_status.status = FETCH_STATUS_IDLE
      state.add_supplier_status.error = undefined
    },
    updateSupplierRequest: (state) => {
      state.update_supplier_status.status = FETCH_STATUS_REQUEST
    },
    updateSupplierSuccess: (state, action) => {
      state.update_supplier_status.status = FETCH_STATUS_SUCCESS
      state.update_supplier_status.response = action.payload
      state.showSupplierResModal = true
    },
    updateSupplierFailure: (state, action) => {
      state.update_supplier_status.status = FETCH_STATUS_ERROR
      state.update_supplier_status.error = action.payload.response.data.error
    },
    updateSupplierIdle: (state) => {
      state.update_supplier_status.status = FETCH_STATUS_IDLE
      state.update_supplier_status.error = undefined
    },
    removeSupplierRequest: (state) => {
      state.remove_supplier_status.status = FETCH_STATUS_REQUEST
    },
    removeSupplierSuccess: (state) => {
      state.remove_supplier_status.status = FETCH_STATUS_SUCCESS
    },
    removeSupplierFailure: (state, action) => {
      state.remove_supplier_status.status = FETCH_STATUS_ERROR
      state.remove_supplier_status.error = action.payload
    },
    removeSupplierIdle: (state) => {
      state.remove_supplier_status.status = FETCH_STATUS_IDLE
      state.remove_supplier_status.error = undefined
    },
    getSupplierRequest: (state) => {
      state.supplier_status.status = FETCH_STATUS_REQUEST
    },
    getSupplierSuccess: (state, action) => {
      state.supplier_status.status = FETCH_STATUS_SUCCESS
      state.supplier_status.error = undefined
      state.supplier = action.payload
    },
    getSupplierFailure: (state, action) => {
      state.supplier_status.status = FETCH_STATUS_ERROR
      state.supplier_status.error = action.payload
    },
    getSupplierIdle: (state) => {
      state.supplier_status.status = FETCH_STATUS_IDLE
    },
    openSupplierResModal: (state) => {
      state.showSupplierResModal = true
    },
    closeSupplierResModal: (state) => {
      state.showSupplierResModal = false
    },
  },
})

export const supplierListState = (state) => state.supplier
export const supplierState = (state) => state.supplier.supplier

const { actions, reducer } = supplierSlice

export const {
  getDefaultExpiredDateRangeRequest,
  getDefaultExpiredDateRangeSuccess,
  getDefaultExpiredDateRangeFailure,
  getDefaultExpiredDateRangeIdle,
  getDefaultRetentionDateRangeRequest,
  getDefaultRetentionDateRangeSuccess,
  getDefaultRetentionDateRangeFailure,
  getDefaultRetentionDateRangeIdle,
  updateDefaultRetentionDateRangeRequest,
  updateDefaultRetentionDateRangeSuccess,
  updateDefaultRetentionDateRangeFailure,
  updateDefaultRetentionDateRangeIdle,
  getListSupplierRequest,
  getListSupplierSuccess,
  getListSupplierFailure,
  getListSupplierIdle,
  addSupplierRequest,
  addSupplierSuccess,
  addSupplierFailure,
  addSupplierIdle,
  updateSupplierRequest,
  updateSupplierSuccess,
  updateSupplierFailure,
  updateSupplierIdle,
  removeSupplierRequest,
  removeSupplierSuccess,
  removeSupplierFailure,
  removeSupplierIdle,
  getSupplierRequest,
  getSupplierSuccess,
  getSupplierFailure,
  getSupplierIdle,
  openSupplierResModal,
  closeSupplierResModal,
} = actions

export default reducer
