import { createSlice } from '@reduxjs/toolkit'

import {
  FETCH_STATUS_IDLE, FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_ERROR,
} from '../../../utils/request/status'

const initialState = {
  tag_list_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  add_tag_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  edit_tag_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  remove_tag_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  remove_multi_tag_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  tagList: [],
}

const tagSlice = createSlice({
  name: 'tag',
  initialState,
  reducers: {
    getTagListRequest: (state) => {
      state.tag_list_status.status = FETCH_STATUS_REQUEST
    },
    getTagListSuccess: (state, action) => {
      state.tag_list_status.status = FETCH_STATUS_SUCCESS
      state.tag_list_status.error = undefined
      state.tagList = action.payload
    },
    getTagListFailure: (state, action) => {
      state.tag_list_status.status = FETCH_STATUS_ERROR
      state.tag_list_status.error = action.payload
    },
    getTagListIdle: (state) => {
      state.tag_list_status.status = FETCH_STATUS_IDLE
      state.tag_list_status.error = undefined
    },
    addTagRequest: (state) => {
      state.add_tag_status.status = FETCH_STATUS_REQUEST
    },
    addTagSuccess: (state) => {
      state.add_tag_status.status = FETCH_STATUS_SUCCESS
      state.add_tag_status.error = undefined
    },
    addTagFailure: (state, action) => {
      state.add_tag_status.status = FETCH_STATUS_ERROR
      state.add_tag_status.error = action.payload
    },
    addTagIdle: (state) => {
      state.add_tag_status.status = FETCH_STATUS_IDLE
      state.add_tag_status.error = undefined
    },
    editTagRequest: (state) => {
      state.edit_tag_status.status = FETCH_STATUS_REQUEST
    },
    editTagSuccess: (state) => {
      state.edit_tag_status.status = FETCH_STATUS_SUCCESS
      state.edit_tag_status.error = undefined
    },
    editTagFailure: (state, action) => {
      state.edit_tag_status.status = FETCH_STATUS_ERROR
      state.edit_tag_status.error = action.payload
    },
    editTagIdle: (state) => {
      state.edit_tag_status.status = FETCH_STATUS_IDLE
      state.edit_tag_status.error = undefined
    },
    removeTagRequest: (state) => {
      state.remove_tag_status.status = FETCH_STATUS_REQUEST
    },
    removeTagSuccess: (state) => {
      state.remove_tag_status.status = FETCH_STATUS_SUCCESS
      state.remove_tag_status.error = undefined
    },
    removeTagFailure: (state, action) => {
      state.remove_tag_status.status = FETCH_STATUS_ERROR
      state.remove_tag_status.error = action.payload
    },
    removeTagIdle: (state) => {
      state.remove_tag_status.status = FETCH_STATUS_IDLE
      state.remove_tag_status.error = undefined
    },
    removeMultiTagRequest: (state) => {
      state.remove_multi_tag_status.status = FETCH_STATUS_REQUEST
    },
    removeMultiTagSuccess: (state) => {
      state.remove_multi_tag_status.status = FETCH_STATUS_SUCCESS
      state.remove_multi_tag_status.error = undefined
    },
    removeMultiTagFailure: (state, action) => {
      state.remove_multi_tag_status.status = FETCH_STATUS_ERROR
      state.remove_multi_tag_status.error = action.payload
    },
    removeMultiTagIdle: (state) => {
      state.remove_multi_tag_status.status = FETCH_STATUS_IDLE
      state.remove_multi_tag_status.error = undefined
    },
  },
})

export const tagState = (state) => state.tag

const { actions, reducer } = tagSlice

export const {
  getTagListRequest,
  getTagListSuccess,
  getTagListFailure,
  getTagListIdle,
  addTagRequest,
  addTagSuccess,
  addTagFailure,
  addTagIdle,
  editTagRequest,
  editTagSuccess,
  editTagFailure,
  editTagIdle,
  removeTagRequest,
  removeTagSuccess,
  removeTagFailure,
  removeTagIdle,
  removeMultiTagRequest,
  removeMultiTagSuccess,
  removeMultiTagFailure,
  removeMultiTagIdle,
} = actions

export default reducer
