import { createSlice } from '@reduxjs/toolkit'
import * as CSV from 'csv-string'

import {
  FETCH_STATUS_IDLE, FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_ERROR,
} from '../../../utils/request/status'

const initialState = {
  access_log_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  export_log_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  accessLogData: undefined,
  exportLogData: [],
}

export const accessLogSlice = createSlice({
  name: 'access_log',
  initialState,
  reducers: {
    getAccessLogDataRequest: (state) => {
      state.access_log_status.status = FETCH_STATUS_REQUEST
    },
    getAccessLogDataSuccess: (state, action) => {
      state.access_log_status.status = FETCH_STATUS_SUCCESS
      state.access_log_status.error = undefined
      state.accessLogData = action.payload
    },
    getAccessLogDataFailure: (state, action) => {
      state.access_log_status.status = FETCH_STATUS_ERROR
      state.access_log_status.error = action.payload
    },
    getAccessLogDataIdle: (state) => {
      state.access_log_status.status = FETCH_STATUS_IDLE
      state.access_log_status.error = undefined
    },
    exportLogDataRequest: (state) => {
      state.export_log_status.status = FETCH_STATUS_REQUEST
    },
    exportLogDataSuccess: (state, action) => {
      state.export_log_status.status = FETCH_STATUS_SUCCESS
      state.export_log_status.error = undefined
      state.exportLogData = CSV.parse(action.payload)
    },
    exportLogDataFailure: (state, action) => {
      state.export_log_status.status = FETCH_STATUS_ERROR
      state.export_log_status.error = action.payload
    },
    exportLogDataIdle: (state) => {
      state.export_log_status.status = FETCH_STATUS_IDLE
      state.export_log_status.error = undefined
    },
  },
})

export const accessLogState = (state) => state.access_log

const { actions, reducer } = accessLogSlice

export const {
  getAccessLogDataRequest,
  getAccessLogDataSuccess,
  getAccessLogDataFailure,
  getAccessLogDataIdle,
  exportLogDataRequest,
  exportLogDataSuccess,
  exportLogDataFailure,
  exportLogDataIdle,
} = actions

export default reducer
