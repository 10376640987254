import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import folderReducer from './slice/folder'
import reportReducer from './slice/report'
import tagReducer from './slice/tag'
import teamReducer from './slice/team'
import supplierReducer from './slice/supplier'
import userReducer from './slice/user'
import usersReducer from './slice/users'
import staffReducer from './slice/staff'
import crossFunctionReducer from './slice/crossFunction'
import accessLogReducer from './slice/access-log'
import monthlyReportReducer from './slice/monthlyReport'
import appReducer from './slice/app'

const createStore = () => {
  const reducer = {
    app: appReducer,
    folder: folderReducer,
    report: reportReducer,
    tag: tagReducer,
    team: teamReducer,
    supplier: supplierReducer,
    user: userReducer,
    users: usersReducer,
    staff: staffReducer,
    crossFunction: crossFunctionReducer,
    access_log: accessLogReducer,
    monthly_report: monthlyReportReducer,
  }
  const middlewares = getDefaultMiddleware({
    serializableCheck: false,
  })
  const store = configureStore({
    reducer,
    middleware: middlewares,
    devTools: process.env.NODE_ENV === 'development',
  })
  return store
}

export default createStore
