export const FETCH_STATUS_IDLE = 'idle'
export const FETCH_STATUS_REQUEST = 'request'
export const FETCH_STATUS_SUCCESS = 'success'
export const FETCH_STATUS_ERROR = 'error'

export const PAGE_STAGE = {
  loading: 'loading',
  finish: 'finish',
  empty: 'empty',
}
