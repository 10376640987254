import { createSlice } from '@reduxjs/toolkit'

import {
  FETCH_STATUS_IDLE, FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_ERROR,
} from '../../../utils/request/status'

const initialState = {
  folder_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  folder_by_id_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  add_folder_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  update_folder_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  remove_folder_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  folderList: [],
  folderById: undefined,
}

const folderSlice = createSlice({
  name: 'folder',
  initialState,
  reducers: {
    getFolderListRequest: (state) => {
      state.folder_status.status = FETCH_STATUS_REQUEST
    },
    getFolderListSuccess: (state, action) => {
      state.folder_status.status = FETCH_STATUS_SUCCESS
      state.folder_status.error = undefined
      state.folderList = action.payload
    },
    getFolderListFailure: (state, action) => {
      state.folder_status.status = FETCH_STATUS_ERROR
      state.folder_status.error = action.payload
    },
    getFolderListIdle: (state) => {
      state.folder_status.status = FETCH_STATUS_IDLE
      state.folder_status.error = undefined
    },
    addFolderRequest: (state) => {
      state.add_folder_status.status = FETCH_STATUS_REQUEST
    },
    addFolderSuccess: (state) => {
      state.add_folder_status.status = FETCH_STATUS_SUCCESS
      state.add_folder_status.error = undefined
    },
    addFolderFailure: (state, action) => {
      state.add_folder_status.status = FETCH_STATUS_ERROR
      state.add_folder_status.error = action.payload
    },
    addFolderIdle: (state) => {
      state.add_folder_status.status = FETCH_STATUS_IDLE
      state.add_folder_status.error = undefined
    },
    updateFolderRequest: (state) => {
      state.update_folder_status.status = FETCH_STATUS_REQUEST
    },
    updateFolderSuccess: (state) => {
      state.update_folder_status.status = FETCH_STATUS_SUCCESS
      state.update_folder_status.error = undefined
    },
    updateFolderFailure: (state, action) => {
      state.update_folder_status.status = FETCH_STATUS_ERROR
      state.update_folder_status.error = action.payload
    },
    updateFolderIdle: (state) => {
      state.update_folder_status.status = FETCH_STATUS_IDLE
      state.update_folder_status.error = undefined
    },
    removeFolderRequest: (state) => {
      state.remove_folder_status.status = FETCH_STATUS_REQUEST
    },
    removeFolderSuccess: (state) => {
      state.remove_folder_status.status = FETCH_STATUS_SUCCESS
      state.remove_folder_status.error = undefined
    },
    removeFolderFailure: (state, action) => {
      state.remove_folder_status.status = FETCH_STATUS_ERROR
      state.remove_folder_status.error = action.payload
    },
    removeFolderIdle: (state) => {
      state.remove_folder_status.status = FETCH_STATUS_REQUEST
      state.remove_folder_status.error = undefined
    },
    getFolderByIdRequest: (state) => {
      state.folder_by_id_status.status = FETCH_STATUS_REQUEST
    },
    getFolderByIdSuccess: (state, action) => {
      state.folder_by_id_status.status = FETCH_STATUS_SUCCESS
      state.folder_by_id_status.error = undefined
      state.folderById = action.payload
    },
    getFolderByIdFailure: (state, action) => {
      state.folder_by_id_status.status = FETCH_STATUS_ERROR
      state.folder_by_id_status.error = action.payload
    },
    getFolderByIdIdle: (state) => {
      state.folder_by_id_status.status = FETCH_STATUS_IDLE
    },
  },
})

export const folderState = (state) => state.folder
export const folderListState = (state) => state.folder.folderList
export const folderByIdState = (state) => state.folder.folderById

const { actions, reducer } = folderSlice

export const {
  getFolderListRequest,
  getFolderListSuccess,
  getFolderListFailure,
  getFolderListIdle,
  addFolderRequest,
  addFolderSuccess,
  addFolderFailure,
  addFolderIdle,
  updateFolderRequest,
  updateFolderSuccess,
  updateFolderFailure,
  updateFolderIdle,
  removeFolderRequest,
  removeFolderSuccess,
  removeFolderFailure,
  removeFolderIdle,
  getFolderByIdRequest,
  getFolderByIdSuccess,
  getFolderByIdFailure,
  getFolderByIdIdle,
} = actions

export default reducer
