import React, { useMemo } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'

import {
  HOME_PAGE_PATH,
  LOGIN_PAGE_PATH,
  SUPPLIER_LIST_PAGE_PATH,
  CREATE_SUPPLIER_PAGE_PATH,
  STAFF_PAGE_PATH,
  STAFF_CRUD_PAGE_PATH,
  RESET_PASSWORD_PAGE_PATH,
  SET_PASSWORD_PAGE_PATH,
  SUPPLIER_USER_PATH,
  SUPPLIER_USER_MANAGE_PATH,
  SUPPLIER_REPORT_LIST_PATH,
  SUPPLIER_REPORT_PATH,
  FOLDER_PAGE_PATH,
  FOLDER_MANAGE_PATH,
  TEAM_PAGE_PATH,
  SUPPLIER_TAG_LIST_PATH,
  TEAM_MANAGE_PATH,
  ACCESS_LOG_PAGE_PATH,
  MONTHLY_REPORT_PATH, SOMETHING_WENT_WRONG_PATH,
} from './routes'

import { userRoleState } from '../store/slice/user'

import ApplicationLayout from '../containers/ApplicationLayout/loadable'
import LoginPage from '../containers/pages/Login/loadable'
import HomePage from '../containers/pages/Home/loadable'
import StaffPage from '../containers/pages/SfaffList/loadable'
import StaffCRUDPage from '../containers/pages/SfaffManagement/loadable'
import SupplierListPage from '../containers/pages/SupplierList/loadable'
import CreateSupplierPage from '../containers/pages/CreateSupplier/loadable'
import ResetPassword from '../containers/pages/ResetPassword/loadable'
import SetPassword from '../containers/pages/SetPassword/loadable'
import UserList from '../containers/pages/UserList/loadable'
import UserManagement from '../containers/pages/UserManagement/loadable'
import ReportList from '../containers/pages/ReportList/loadable'
import ReportManagement from '../containers/pages/ReportManagement/loadable'
import FolderList from '../containers/pages/FolderList/loadable'
import FolderManagement from '../containers/pages/FolderManagement/loadable'
import TeamList from '../containers/pages/TeamList/loadable'
import TagList from '../containers/pages/TagList/loadable'
import TeamManagement from '../containers/pages/TeamManagement/loadable'
import AccessLog from '../containers/pages/AccessLog/loadable'
import MonthlyReport from '../containers/pages/MonthlyReport/loadable'
import PageNotFound from '../containers/pages/PageNotFound/loadable'
import SomethingWentWrongPage from '../containers/pages/SomethingWentWrongPage/loadable'

const RouterPage = () => {
  const userRole = useSelector(userRoleState)

  const getAllPage = useMemo(() => (
    <Switch>
      <Route path={LOGIN_PAGE_PATH} component={LoginPage} />
      <Route path={RESET_PASSWORD_PAGE_PATH} component={ResetPassword} />
      <Route path={SET_PASSWORD_PAGE_PATH} component={SetPassword} />
      <ApplicationLayout>
        <Switch>
          <Route exact path={SUPPLIER_LIST_PAGE_PATH} component={SupplierListPage} />
          <Route exact path={CREATE_SUPPLIER_PAGE_PATH} component={CreateSupplierPage} />
          <Route exact path={STAFF_PAGE_PATH} component={StaffPage} />
          <Route exact path={STAFF_CRUD_PAGE_PATH} component={StaffCRUDPage} />
          <Route exact path={SUPPLIER_REPORT_PATH} component={ReportManagement} />

          <Route exact path={HOME_PAGE_PATH} component={HomePage} />
          <Route exact path={SUPPLIER_USER_PATH} component={UserList} />
          <Route path={SUPPLIER_USER_MANAGE_PATH} component={UserManagement} />
          <Route path={SUPPLIER_REPORT_LIST_PATH} component={ReportList} />
          <Route path={SUPPLIER_TAG_LIST_PATH} component={TagList} />
          <Route exact path={FOLDER_PAGE_PATH} component={FolderList} />
          <Route path={FOLDER_MANAGE_PATH} component={FolderManagement} />
          <Route exact path={TEAM_PAGE_PATH} component={TeamList} />
          <Route path={TEAM_MANAGE_PATH} component={TeamManagement} />
          <Route path={ACCESS_LOG_PAGE_PATH} component={AccessLog} />
          <Route path={MONTHLY_REPORT_PATH} component={MonthlyReport} />
          <Route path={SOMETHING_WENT_WRONG_PATH} component={SomethingWentWrongPage} />
          <Route component={PageNotFound} />
        </Switch>
      </ApplicationLayout>
    </Switch>
  ), [userRole])

  return (
    <div className="App" id='App'>
      {getAllPage}
    </div>
  )
}

export default RouterPage
