export const HOME_PAGE_PATH = '/'
export const LOGIN_PAGE_PATH = '/login'

export const SUPPLIER_LIST_PAGE_PATH = '/supplier-list'
export const CREATE_SUPPLIER_PAGE_PATH = '/create-supplier'

export const SUPPLIER_USER_PATH = '/supplier/user'
export const SUPPLIER_USER_MANAGE_PATH = '/supplier/user/manage'

export const SUPPLIER_REPORT_LIST_PATH = '/supplier/reports'
export const SUPPLIER_REPORT_PATH = '/supplier/report'

export const SUPPLIER_TAG_LIST_PATH = '/supplier/tags'

export const STAFF_PAGE_PATH = '/staffs'
export const STAFF_CRUD_PAGE_PATH = '/staffs/manage'

export const RESET_PASSWORD_PAGE_PATH = '/resetPassword'
export const SET_PASSWORD_PAGE_PATH = '/setPassword'

export const FOLDER_PAGE_PATH = '/supplier/folder'
export const FOLDER_MANAGE_PATH = '/supplier/folder/manage'

export const TEAM_PAGE_PATH = '/supplier/team'
export const TEAM_MANAGE_PATH = '/supplier/team/manage'

export const ACCESS_LOG_PAGE_PATH = '/supplier/access-log'

export const MONTHLY_REPORT_PATH = '/supplier/monthly-report'

export const SOMETHING_WENT_WRONG_PATH = '/something-went-wrong'
