const themeColor = {
  primaryDark: '#005C43',
  primaryLight: '#29aa68',
  secondary: '#FB8634',
  whitePrimary: '#FFFFFF',
  whiteSecondary: '#F2F4F7',
  whiteTertiary: '#F1F7FF',
  whiteQuaternary: '#C9F4E2',
  whiteQuinary: '#D1E7DE',
  greyPrimary: '#D6DEE5',
  greySecondary: '#B3BAC0',
  greyTertiary: '#5E6164',
  greyQuaternary: '#3D4248',
  successful: '#70C12E',
  error: '#D6423E',
  warningPrimary: '#FBEDC1',
  warningSecondary: '#F1C022',
}

export default themeColor
