import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  somethingWentWrong: false,
  errorCode: undefined,
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setUnHandleError: (state, action) => {
      state.somethingWentWrong = action.payload.contain
      state.errorCode = action.payload.statusCode
    },
  },
})

export const appState = (state) => state.app

const { actions, reducer } = appSlice

export const {
  setUnHandleError,
} = actions

export default reducer
