import { createSlice } from '@reduxjs/toolkit'

import {
  FETCH_STATUS_IDLE,
  FETCH_STATUS_REQUEST,
  FETCH_STATUS_SUCCESS,
  FETCH_STATUS_ERROR,
} from '../../../utils/request/status'

const initialState = {
  list_user_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  add_user_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  update_user_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  remove_user_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  list_supplier_user_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
    firstCall: true,
  },
  add_supplier_user_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  update_supplier_user_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  remove_supplier_user_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  get_user_by_id_status: {
    status: FETCH_STATUS_IDLE,
    error: undefined,
  },
  listUser: [],
  listSupplierUser: [],
  userById: undefined,
}

const listUserSlice = createSlice({
  name: 'listUser',
  initialState,
  reducers: {
    getListSupplierUserRequest: (state) => {
      state.list_supplier_user_status.status = FETCH_STATUS_REQUEST
      state.list_supplier_user_status.firstCall = false
    },
    getListSupplierUserSuccess: (state, action) => {
      state.list_supplier_user_status.status = FETCH_STATUS_SUCCESS
      state.list_supplier_user_status.error = undefined
      state.listSupplierUser = action.payload.map((user) => ({
        ...user,
        name: `${user.firstname} ${user.lastname}`,
      }))
    },
    getListSupplierUserFailure: (state, action) => {
      state.list_supplier_user_status.status = FETCH_STATUS_ERROR
      state.list_supplier_user_status.error = action.payload
    },
    getListSupplierUserIdle: (state) => {
      state.list_supplier_user_status.status = FETCH_STATUS_IDLE
    },
    addSupplierUserRequest: (state) => {
      state.add_supplier_user_status.status = FETCH_STATUS_REQUEST
    },
    addSupplierUserSuccess: (state) => {
      state.add_supplier_user_status.status = FETCH_STATUS_SUCCESS
      state.add_supplier_user_status.error = undefined
    },
    addSupplierUserFailure: (state, action) => {
      state.add_supplier_user_status.status = FETCH_STATUS_ERROR
      state.add_supplier_user_status.error = action.payload.response.data?.error
    },
    addSupplierUserIdle: (state) => {
      state.add_supplier_user_status.status = FETCH_STATUS_IDLE
    },
    updateSupplierUserRequest: (state) => {
      state.update_supplier_user_status.status = FETCH_STATUS_REQUEST
    },
    updateSupplierUserSuccess: (state, action) => {
      state.update_supplier_user_status.status = FETCH_STATUS_SUCCESS
      state.update_supplier_user_status.error = undefined
      state.update_supplier_user_status.response = action.payload
    },
    updateSupplierUserFailure: (state, action) => {
      state.update_supplier_user_status.status = FETCH_STATUS_ERROR
      state.update_supplier_user_status.error = action.payload
    },
    updateSupplierUserIdle: (state) => {
      state.update_supplier_user_status.status = FETCH_STATUS_IDLE
    },
    removeSupplierUserRequest: (state) => {
      state.remove_supplier_user_status.status = FETCH_STATUS_REQUEST
    },
    removeSupplierUserSuccess: (state) => {
      state.remove_supplier_user_status.status = FETCH_STATUS_SUCCESS
      state.remove_supplier_user_status.error = undefined
    },
    removeSupplierUserFailure: (state, action) => {
      state.remove_supplier_user_status.status = FETCH_STATUS_ERROR
      state.remove_supplier_user_status.error = action.payload
    },
    removeSupplierUserIdle: (state) => {
      state.remove_supplier_user_status.status = FETCH_STATUS_IDLE
    },
    getUserByIdRequest: (state) => {
      state.get_user_by_id_status.status = FETCH_STATUS_REQUEST
    },
    getUserByIdSuccess: (state, action) => {
      state.get_user_by_id_status.status = FETCH_STATUS_SUCCESS
      state.get_user_by_id_status.error = undefined
      state.userById = action.payload
    },
    getUserByIdFailure: (state, action) => {
      state.get_user_by_id_status.status = FETCH_STATUS_ERROR
      state.get_user_by_id_status.error = action.payload
    },
    getUserByIdIdle: (state) => {
      state.get_user_by_id_status.status = FETCH_STATUS_IDLE
    },
  },
})

export const listUserState = (state) => state.users
export const listSupplierUserState = (state) => state.users.listSupplierUser
export const userByIdState = (state) => state.users.userById

const { actions, reducer } = listUserSlice

export const {
  getListSupplierUserRequest,
  getListSupplierUserSuccess,
  getListSupplierUserFailure,
  getListSupplierUserIdle,
  addSupplierUserRequest,
  addSupplierUserSuccess,
  addSupplierUserFailure,
  addSupplierUserIdle,
  updateSupplierUserRequest,
  updateSupplierUserSuccess,
  updateSupplierUserFailure,
  updateSupplierUserIdle,
  removeSupplierUserRequest,
  removeSupplierUserSuccess,
  removeSupplierUserFailure,
  removeSupplierUserIdle,
  getUserByIdRequest,
  getUserByIdSuccess,
  getUserByIdFailure,
  getUserByIdIdle,
} = actions

export default reducer
